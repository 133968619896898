eslint-disable vue/multi-word-component-names
<template>
    <el-container>
        <el-main>
            <div style="text-align: left;margin-top: -50px">
                <h1 style="font-size: 50px">南宁安吉段列车一览</h1>
            </div>
            <!--          // v-for循环对象的时候有三个参数 第一个是键值对的值（value）-->
            <!--          // 第二个是键值对的键（key）第三个是下标 (index)-->

            <!--          <div v-for="(value, key, index) in ArrCarData" :key="index">-->
            <!--            <h3>{{key}} &#45;&#45;&#45;&#45; {{index}}</h3>-->


            <!--            <h3>{{value["carnum"]}}</h3>-->
            <!--            <ul>-->
            <!--              <li v-for="item in value" :key="item">{{item}}</li>-->
            <!--            </ul>-->
            <!--          </div>-->

            <div style="margin-left: 10%;margin-right: 10%">
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(0, 4)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(4, 8)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(8, 12)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(12, 16)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(16, 20)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(20, 24)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6" v-for="(value, key, index) in ArrCarData.slice(24, 28)" :key="index">
                        <div class="grid-content" :id="getCarStatusType(value.status)">
                            <el-link :href="'/car?id='+value.carnum" :type="getCarStatusType(value.status)">
                                {{value["carnum"]}}
                            </el-link>
                        </div>
                    </el-col>
                </el-row>
            </div>


        </el-main>
    </el-container>
</template>

<script>

    export default {
        name: 'HomePage',
        data() {
            return {
                ArrCarData: [{
                    "id": "1",
                    "carnum": "c0201",
                    "status": "1good",
                    "lasttime": "2023y12m30d22h",
                    "note": " ",
                    "bak": " "
                }, {
                    "id": "2",
                    "carnum": "c0202",
                    "status": "1good",
                    "lasttime": "2023y11m12d11h",
                    "note": "",
                    "bak": ""
                }]
            }
        },
        created() {
            this.$http.get(this.baseUrl+'/vuexnn/nnv2phpapi/getallcarstatus.php').then(res => {
                console.log(res);
                this.ArrCarData = res.data;
            });
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
                this.$router.push(key);
            },
            getCarStatusType(statuscode) {
                if (statuscode === "0no") {
                    return "info"
                }
                if (statuscode === "1good") {
                    return "success"
                }
                if (statuscode === "2normal") {
                    return "warning"
                }
                if (statuscode === "3bad") {
                    return "danger"
                }
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /deep/ .el-link--inner{
        font-size:50px;
    }
    .el-row {
        margin-bottom: 30px;

    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 60px;
    }


    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    #info {
        background: #e9e9eb;
    }

    #success {
        background: #e1f3d8;
    }

    #warning {
        background: #faecd8;
    }

    #danger {
        background: #fde2e2;
    }
</style>
