<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <el-container>
        <el-main>
            <h1 style="font-size: 50px;line-height: 0.1%">当前车号：{{carname}} -最近检测时间：{{ArrThisCar[0]['time']}}</h1>
            <el-row>
                <el-col :span="4">
                    <el-image
                            style="width: 100%; height: 100px"
                            :src="getCarStatusType(ArrThisCar[0]['P1'],'P1')"
                            fit="fill"></el-image>
                </el-col>
                <el-col :span="4">
                    <el-image
                            style="width: 100%; height: 100px"
                            :src="getCarStatusType(ArrThisCar[0]['P2'],'P2')"
                            fit="fill"></el-image>
                </el-col>
                <el-col :span="4">
                    <el-image
                            style="width: 100%; height: 100px"
                            :src="getCarStatusType(ArrThisCar[0]['P3'],'P3')"
                            fit="fill"></el-image>
                </el-col>
                <el-col :span="4">
                    <el-image
                            style="width: 100%; height: 100px"
                            :src="getCarStatusType(ArrThisCar[0]['P4'],'P4')"
                            fit="fill"></el-image>
                </el-col>
                <el-col :span="4">
                    <el-image
                            style="width: 100%; height: 100px"
                            :src="getCarStatusType(ArrThisCar[0]['P5'],'P5')"
                            fit="fill"></el-image>
                </el-col>
                <el-col :span="4">
                    <el-image
                            style="width: 100%; height: 100px"
                            :src="getCarStatusType(ArrThisCar[0]['P6'],'P6')"
                            fit="fill"></el-image>
                </el-col>

            </el-row>
            <el-row>
                <el-col :span="4">
                    <el-button :type="getCarButtonSta(ArrThisCar[0]['P1'],'P1')" @click="handleClick('01')">
                        查看车厢01详细
                    </el-button>
                </el-col>
                <el-col :span="4">
                    <el-button :type="getCarButtonSta(ArrThisCar[0]['P2'],'P2')" @click="handleClick('02')">查看车厢02详细
                    </el-button>
                </el-col>
                <el-col :span="4">
                    <el-button :type="getCarButtonSta(ArrThisCar[0]['P3'],'P3')" @click="handleClick('03')">查看车厢03详细</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button :type="getCarButtonSta(ArrThisCar[0]['P4'],'P4')" @click="handleClick('04')">查看车厢04详细</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button :type="getCarButtonSta(ArrThisCar[0]['P5'],'P5')" @click="handleClick('05')">查看车厢05详细</el-button>
                </el-col>
                <el-col :span="4">
                    <el-button :type="getCarButtonSta(ArrThisCar[0]['P6'],'P6')" @click="handleClick('06')">查看车厢06详细</el-button>
                </el-col>
            </el-row>


            <el-dialog
                    title="车厢详情"
                    :visible.sync="dialogVisible"
                    width="50%"
                    :fullscreen=true
                    @opened="[drawLine1('La'),drawLine1('Lb'),drawLine1('Lc'),drawLine1('Ld'),
                                drawLine1('Ra'),drawLine1('Rb'),drawLine1('Rc'),drawLine1('Rd')
                                ]"
                    :before-close="handleClose">


                <el-row style="margin-top: -100px">
                    <el-col :span="10">左侧轮对
                        <div class="testTwo">
                            <div id="La" style="width:100%;height:260px;float:left;"></div>
                        </div>
                        <div class="testTwo">
                            <div id="Lb" style="width:100%;height:260px;float:left;"></div>
                        </div>
                        <div class="testTwo">
                            <div id="Lc" style="width:100%;height:260px;float:left;"></div>
                        </div>
                        <div class="testTwo">
                            <div id="Ld" style="width:100%;height:260px;float:left;"></div>
                        </div>
                    </el-col>

                    <el-col :span="4" style="margin-top: 100px">
                        <h1 style="font-size: x-large;">车号信息：{{carname}}-{{carboxnum}}车</h1>
                        <p style="margin-top: 20px;"></p>
<!--                        <el-button @click="dialogVisible = false">取 消</el-button>-->
                        <el-button type="primary" @click="dialogVisible = false">返回上一级</el-button>
                        <el-image
                                style="width: 500px; height: 300px;transform: rotate(90deg);margin-top: 200px;margin-left: -120px"
                                :src="getCarStatusType(ArrThisCar[0][inImgPx],inImgPx)"
                                fit="fill"></el-image>

                    </el-col>
                    <el-col :span="10">右侧轮对
                        <div class="testTwo">
                            <div id="Ra" style="width:100%;height:260px;float:left;"></div>
                        </div>
                        <div class="testTwo">
                            <div id="Rb" style="width:100%;height:260px;float:left;"></div>
                        </div>
                        <div class="testTwo">
                            <div id="Rc" style="width:100%;height:260px;float:left;"></div>
                        </div>
                        <div class="testTwo">
                            <div id="Rd" style="width:100%;height:260px;float:left;"></div>
                        </div></el-col>
                </el-row>

<!--                <span slot="footer" class="dialog-footer">-->

<!--  </span>-->
            </el-dialog>
        </el-main>
    </el-container>

</template>

<script>
    export default {
        name: 'TrainData',
        data() {
            return {
                carname: 'c0000',
                carboxnum: '00',
                ArrThisCar: [{"id":"26","carnum":"c0226","P1":"1good","P2":"1good","P3":"1good","P4":"1good","P5":"1good","P6":"1good","time":"2023-12-31-02H","note":"","bak":""}],
                dialogVisible: false,
                myChart2: '',
                dTime: '',
                dAvg: '',
                dMax: '',
                btnVal: '',
                carAllInfo:'',
                inImgPx :''
            }
        },
        async created() {
            this.carname = this.$route.query.id;
            await this.getData(); // 使用async/await确保数据获取完毕
        },
        // mounted: function () {
        //     this.getData()
        //         .then(() => {
        //             // this.drawLine1();
        //         })
        //         .catch((error) => {
        //             console.error('获取数据失败:', error);
        //         });
        // },
        // mounted() {
        //     this.$nextTick(() => {
        //         this.drawLine1();
        //         // 在这里进行图表绘制
        //     });
        // },
        methods: {
            handleClick(param) {
                // 在这里处理点击事件，并使用传入的参数
                console.log(param); // 输出 "参数值"
                // console.log(this.dTime);
                this.dialogVisible = true;
                this.carboxnum = param;
                this.inImgPx = "P"+param.charAt(param.length - 1)
                // this.drawLine1();


            },
            showval() {
                console.log(this.carname);
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {// eslint-disable-line no-unused-vars
                        done();
                    })
                    .catch(_ => {// eslint-disable-line no-unused-vars
                    });
            },
            getCarButtonSta(statuscode) {
                if (statuscode === "1good") {
                    return "success"
                }
                if (statuscode === "2normal") {
                    return "warning"
                }
                if (statuscode === "3bad") {
                    return "danger"
                }
            },
            getCarStatusType(statuscode, Px) {
                if (Px === "P1") {
                    if (statuscode === "1good") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Agood.png"
                    }
                    if (statuscode === "2normal") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Anormal.png"
                    }
                    if (statuscode === "3bad") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Abad.png"
                    }
                } else if (Px === "P6") {
                    if (statuscode === "1good") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Cgood.png"
                    }
                    if (statuscode === "2normal") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Cnormal.png"
                    }
                    if (statuscode === "3bad") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Cbad.png"
                    }
                } else {
                    if (statuscode === "1good") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Bgood.png"
                    }
                    if (statuscode === "2normal") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Bnormal.png"
                    }
                    if (statuscode === "3bad") {
                        return this.baseUrl+"/vuexnn/nnv2phpapi/pic/Bbad.png"
                    }
                }

            },
            async getData() {
                try {
                    await this.$http.get(this.baseUrl+'/vuexnn/nnv2phpapi/testphp.php?carname=' + this.carname).then(res => {
                        console.log(res);
                        this.carAllInfo = res.data;

                        // console.log(this.dAvg);
                    });
                    await this.$http.get(this.baseUrl+'/vuexnn/nnv2phpapi/getcarpstatus.php?carnum=' + this.carname).then(res => {
                        console.log(res);
                        this.ArrThisCar = res.data;
                    });
                } catch (error) {
                    console.error('获取数据失败:', error);
                }
            },
            drawLine1(myChartid) {
                // this.myChart2 = this.$echarts.init(this.$refs.myDialog.$el.querySelector('#myChart2'));
                // 基于准备好的dom，初始化echarts实例
                this.myChart2 = this.$echarts.init(document.getElementById(myChartid));
                // this.myChart2 = echarts.init(document.getElementById('myChart2'));
                // 绘制图表
                let tLR = "L";
                if(myChartid[0]==='L'){
                    tLR = 'L';
                }else {
                    tLR = 'R';
                }
                let baseCont = Number(this.carboxnum)*4;
                if(myChartid[1]==='a'){
                    baseCont = baseCont-3;
                }else if(myChartid[1]==='b'){
                    baseCont = baseCont-2;
                }else if(myChartid[1]==='c'){
                    baseCont = baseCont-1;
                }else if(myChartid[1]==='d'){
                    console.log();
                }
                this.dTime = this.carAllInfo[baseCont][tLR]['timestamps'];
                this.dAvg =  this.carAllInfo[baseCont][tLR]['averages'];
                this.dMax =  this.carAllInfo[baseCont][tLR]['maximums'];
                this.myChart2.setOption({
                    title: {
                        text: tLR+baseCont+'轮情况', // 主标题
                        subtext: '', // 副标题
                        x: 'left' // x轴方向对齐方式
                    },
                    tooltip: {
                        trigger: 'axis' // axis   item   none三个值
                    },
                    xAxis: {
                        type: 'category', // 还有其他的type，可以去官网喵两眼哦
                        data: this.dTime, // x轴数据
                        name: '' // x轴名称
                        // x轴名称样式
                        // nameTextStyle: {
                        //   fontWeight: 600,
                        //   fontSize: 18
                        // }
                    },
                    yAxis: {
                        type: 'value',
                        name: '伤损指数' // y轴名称
                        // y轴名称样式
                        // nameTextStyle: {
                        //   fontWeight: 600,
                        //   fontSize: 18
                        // }
                    },
                    legend: {
                        orient: 'vertical',
                        x: 'center',
                        y: 'top',
                        data: ['单次检测最大值', '单次检测平均值'],

                    },
                    series: [
                        // {
                        //     name: '单次检测最大值',
                        //     data: this.dMax,
                        //     type: 'line',
                        //     lineStyle: { // 定义折线样式
                        //         // color: '#b3d8ff', // 蓝色线条
                        //         width: 3, // 线条宽度为2px
                        //         type: 'solid' // 实线样式
                        //     }
                        // },
                        {
                            name: '单次检测平均值',
                            data: this.dAvg,
                            type: 'line',
                            lineStyle: { // 定义折线样式
                                // color: '#c6e2ff', // 蓝色线条
                                width: 3, // 线条宽度为2px
                                type: 'solid' // 实线样式
                            }
                        }
                    ],
                    visualMap:{
                        pieces:[
                            {
                                lte:0.65,
                                color:"#67c23a"
                            },
                            {
                                gt:0.65,
                                lte:0.8,
                                color:"#e6be3c"
                            },
                            {
                                gt:0.8,
                                color:"#f56c6c"
                            },
                        ],
                        left:"center",//标签距离左边的距离，还可以用left。center等字段
                        top: "25",//标签距离顶部距离。还可以用top。middle等字段
                        orient: "horizontal",//标签排列方式，水平和垂直
                        show:false,//标签是否显示
                    },
                })
            },
        },

    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /deep/ .el-button {
        margin-top: 20px;
        font-size: x-large;
    }
    .testTwo{
        width:800px;height:250px;
    }

    #img2 {
        transform: rotate(90deg);
    }
</style>
