import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'            //引入ElementUI
import 'element-ui/lib/theme-chalk/index.css' //导入样式
import axios from 'axios'                     //引入axios
import router from './router'
import VueCookies from 'vue-cookies'
//引入 echarts
import * as echarts from 'echarts'
//注册组件
Vue.prototype.$echarts = echarts
Vue.prototype.baseUrl = "http://nn2.gtea.icu"
// Vue.prototype.baseUrl = "http://localhost"
Vue.prototype.$http = axios;
Vue.use(ElementUI)                            //使用
Vue.config.productionTip = false
Vue.use(VueCookies)
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
