<template>

  <div id="app">
    <el-container>
      <el-header>

        <el-row>
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
                   active-text-color="#1989fa" text-color="#409eff">
            <!-- <el-menu-item index="/index" style="width: 5%;">
            </el-menu-item> -->
            <el-menu-item >
              <img src="../public/logo.png" alt="" style="height:90px">
            </el-menu-item>
            <el-menu-item index="/" style="width: 15%;">首页</el-menu-item>
            <!-- <div>南宁轨道在线监测系统</div> -->
            <el-menu-item index="/car" style="width: 15%;">车辆详情</el-menu-item>
            <el-menu-item index="/down" style="width: 15%;">导出页</el-menu-item>
            <el-menu-item >
              <img src="../public/liu.jpg" alt="" style="height:90px">
            </el-menu-item>

          </el-menu>

        </el-row>


      </el-header>
      <el-main style="margin-top: 30px;">
        <router-view/>
      </el-main>
    </el-container>
    <!-- <el-menu-item index="/index">主页</el-menu-item>
    <el-menu-item index="/users">个人TODO</el-menu-item> -->
     <!-- <router-view></router-view> -->
    <!-- <Home/> -->
  </div>
</template>

<script>
// import Home from './components/Home.vue'

export default {
  data() {
      return {
        url:'../public/logo.png',
        activeIndex: '1',
      }
    },
  name: 'App',
  methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
        this.$router.push(key);
      },
  }
}
</script>

<style>
.el-menu-item{
height:90px !important;
font-size:40px !important;
  line-height: 180% !important;
  font-weight:bolder !important;
}
/*.el-link--inner {*/
/*  font-size:50px !important;*/
/*}*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}


</style>
