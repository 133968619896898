import Vue from 'vue'
import Router from 'vue-router'
import Home from '../components/Home.vue'
import TrainData from '../components/TrainData.vue'
import DownloadPage from "../components/DownloadPage";
Vue.use(Router)
export default new Router({
    routes:[
        {
            path:'/',
            component:Home
        },
        {
          path: '/car',
          component:TrainData
        },
        {
            path: '/down',
            component:DownloadPage
        },
        
    ],
    mode:'history'
})
// 解决点击相同路由报错 NavigationDuplicated: Avoided redundant navigation to current location: "/home".
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}