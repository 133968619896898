<!-- eslint-disable vue/multi-word-component-names -->
<template>

    <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline" style="font-size: 30px">
            <el-form-item label="下载人：" >
                <el-input v-model="formInline.user" placeholder="填入姓名"></el-input>
            </el-form-item>
            <el-form-item label="选择车号数据：">
                <el-select v-model="formInline.region" placeholder="选择车号">
                    <el-option label="c0201" value="c0201"></el-option>
                    <el-option label="c0202" value="c0202"></el-option>
                    <el-option label="c0203" value="c0203"></el-option>
                    <el-option label="c0204" value="c0204"></el-option>
                    <el-option label="c0205" value="c0205"></el-option>
                    <el-option label="c0206" value="c0206"></el-option>
                    <el-option label="c0207" value="c0207"></el-option>
                    <el-option label="c0208" value="c0208"></el-option>
                    <el-option label="c0209" value="c0209"></el-option>
                    <el-option label="c0210" value="c0210"></el-option>
                    <el-option label="c0211" value="c0211"></el-option>
                    <el-option label="c0212" value="c0212"></el-option>
                    <el-option label="c0213" value="c0213"></el-option>
                    <el-option label="c0214" value="c0214"></el-option>
                    <el-option label="c0215" value="c0215"></el-option>
                    <el-option label="c0216" value="c0216"></el-option>
                    <el-option label="c0217" value="c0217"></el-option>
                    <el-option label="c0218" value="c0218"></el-option>
                    <el-option label="c0219" value="c0219"></el-option>
                    <el-option label="c0220" value="c0220"></el-option>
                    <el-option label="c0221" value="c0221"></el-option>
                    <el-option label="c0222" value="c0222"></el-option>
                    <el-option label="c0223" value="c0223"></el-option>
                    <el-option label="c0224" value="c0224"></el-option>
                    <el-option label="c0225" value="c0225"></el-option>
                    <el-option label="c0226" value="c0226"></el-option>
                    <el-option label="c0227" value="c0227"></el-option>
                    <el-option label="c0228" value="c0228"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">下载</el-button>
            </el-form-item>
        </el-form>
        <div v-if="down_click_done">
            <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%" >
                <el-table-column
                        prop="id"
                        label="id">
                </el-table-column>
                <el-table-column
                        prop="user"
                        label="姓名"
                >
                </el-table-column>
                <el-table-column
                        prop="car"
                        label="车号"
                >
                </el-table-column>
                <el-table-column
                        prop="time"
                        label="时间">
                </el-table-column>
            </el-table>
        </div>
        <div v-if="!down_click_done">
            <h1>已处理下载任务,可直接继续下载~</h1>
        </div>


    </div>


</template>

<script>
    export default {
        name: 'testTwo',
        data() {
            return {
                formInline: {
                    user: '',
                    region: ''
                },
                tableData: [
                    [{"id":"1","user":"已点击下载","car":"刷新页面可恢复列表","time":""}]
                ],
                down_click_done:true,
                dataLoadOk: false,
                myChart2: '',
                dTime: '',
                dAvg: '',
                dMax: '',

            }
        },
        async created() {
            await this.getData(); // 使用async/await确保数据获取完毕
        },
        mounted: function () {
            this.getData()
                .then(() => {
                    this.drawLine1();
                })
                .catch((error) => {
                    console.error('获取数据失败:', error);
                });
        },
        methods: {
            onSubmit() {
                console.log('submit!');
                console.log(this.formInline.region);
                this.$http.get(this.baseUrl+'/vuexnn/nnv2phpapi/adddownuser.php?carnum=' + this.formInline.region + '&user='+ this.formInline.user).then(res => {
                    console.log(res);
                    this.tableData = res.data;
                });
                this.down_click_done = false;
                // this.downloadUrl = 'http://118.195.163.121:8028/getcsv/'+this.formInline.region;
                // console.log(this.downloadUrl)
                // this.$refs.downloadLink.click(); // 触发<a>标签的点击事件
                window.location.href = 'https://nn2.gtea.icu:8028/getcsv/'+this.formInline.region;
                // this.getData();
            },



            async getData() {
                try {
                    await this.$http.get(this.baseUrl+'/vuexnn/nnv2phpapi/getdownuser.php').then(res => {
                        console.log(res);
                        this.tableData = res.data;
                    });
                } catch (error) {
                    console.error('获取数据失败:', error);
                }
            },

        }
    }
</script>


<style>
    .el-form-item__label{
        font-size: 26px !important;
    }
</style>